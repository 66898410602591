<template>
    <app-wrapper>
        <template v-slot:child>
            <Spinner v-if="!entity" />
            <div v-else class="w-100 center pt0-l mini-spacing">
                <div class="w-90 pv2 center box-border" style="background: white">
                    <div class="flex justify-between pa3">
                        <div v-if="state.firstName || state.lastName" class="box-border-bottom f3 pa3 b">
                            Update {{ `${state.firstName} ${state.lastName}'s details` }}
                        </div>
                        <div v-else-if="state.company" class="box-border-bottom f3 pa3 b">
                            Update {{ `${state.company}'s details` }}
                        </div>
                    </div>

                    <!--      Previously div tag-->
                    <form class="entity-form w-100" @submit.prevent="onSubmit" ref="formRef">
                        <div class="pa3">
                            <!-- full name -->
                            <div class="flex flex-wrap items-end justify-between">
                                <div class="w-50-l w-100 pr2-l pb0-l pb3">
                                    <div class="pv2 font-w1">
                                        <label for="firstName">First Name<span class="required">*</span></label>
                                    </div>
                                    <!-- <input type="text" placeholder="First name" id="firstName" name="firstName" v-model="state.firstName" />-->
                                    <input
                                        type="text"
                                        ref="firstNameRef"
                                        placeholder="First name"
                                        id="firstName"
                                        name="firstName"
                                        v-model="state.firstName"
                                    />
                                </div>
                                <div class="w-50-l w-100 pl2-l pb0-l">
                                    <div class="pv2 font-w1">
                                        <label for="lastName">Last Name</label>
                                    </div>
                                    <!--  input type="text" placeholder="Last Name" id="lastName" name="lastName" v-model="state.lastName" />-->
                                    <input
                                        type="text"
                                        ref="lastNameRef"
                                        placeholder="Last Name"
                                        id="lastName"
                                        name="lastName"
                                        v-model="state.lastName"
                                    />
                                </div>
                            </div>
                            <!-- Company & number -->
                            <div class="flex flex-wrap justify-between pt2">
                                <div class="w-50-l w-100 pr2-l">
                                    <div class="pv2 font-w1">
                                        <label for="company">Company Name<span class="required">*</span></label>
                                    </div>
                                    <input
                                        type="text"
                                        id="company"
                                        placeholder="Company name"
                                        name="company"
                                        v-model="state.company"
                                    />
                                </div>
                                <div class="w-50-l w-100 pl2-l">
                                    <div class="pv2 font-w1">
                                        <label for="phone">Phone Number</label>
                                    </div>
                                    <!-- <input type="text" id="phone" name="phone" placeholder="08055693214" v-model="state.phone" /> -->
                                    <vue-tel-input
                                        :type="'tel'"
                                        :placeholder="'Enter your phone number'"
                                        class="box-border w-100"
                                        :id="'phone'"
                                        :name="'phone'"
                                        mode="international"
                                        :defaultCountry="'NG'"
                                        v-model="state.phone"
                                    ></vue-tel-input>
                                </div>
                            </div>
                            <!-- email & website -->
                            <div class="flex flex-wrap justify-between pt2">
                                <div class="w-50-l w-100 pr2-l">
                                    <div class="pv2 font-w1"><label for="email">Email</label></div>
                                    <div class="input-group">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            v-model="state.email"
                                            @change="validateEmail"
                                            placeholder="joe@yahoo.com"
                                        />
                                        <span v-if="state.isEmailValid && !state.isValidatingEmail" class="input-group-label">
                                            <font-awesome-icon icon="fa-solid fa-check" class="fa-2x text-success" />
                                        </span>
                                        <span
                                            v-else-if="state.isEmailValid === false && state.email !== ''"
                                            class="input-group-label"
                                        >
                                            <font-awesome-icon icon="fa-solid fa-remove" class="fa-2x text-danger" />
                                        </span>
                                        <span v-else-if="state.isValidatingEmail" class="input-group-label">
                                            <font-awesome-icon icon="fa-solid fa-circle-notch" class="fa-2x fa-spin" />
                                        </span>
                                    </div>
                                </div>
                                <div class="w-50-l w-100 pl2-l">
                                    <div class="pv2 font-w1">
                                        <label for="website">Website</label>
                                    </div>
                                    <input
                                        type="text"
                                        id="website"
                                        name="website"
                                        v-model="state.website"
                                        placeholder="www.simplebks.com"
                                    />
                                </div>
                            </div>
                            <!-- Address -->
                            <div class="pt2">
                                <div class="pv2 font-w1">
                                    <label for="address1">Address</label>
                                </div>
                                <input
                                    type="text"
                                    id="address1"
                                    name="address1"
                                    v-model="state.address1"
                                    placeholder="Street Address"
                                />
                                <input
                                    type="text"
                                    class="mt3"
                                    id="address2"
                                    name="address"
                                    v-model="state.address2"
                                    placeholder="Street Address 2"
                                />
                            </div>
                            <!-- city/state -->
                            <div class="flex pt3">
                                <div class="w-50 pr2">
                                    <input type="text" id="city" name="city" v-model="state.city" placeholder="City" />
                                </div>
                                <div class="w-50 pl2">
                                    <input
                                        type="text"
                                        id="state"
                                        name="state"
                                        v-model="state.state"
                                        placeholder="State/Province"
                                    />
                                </div>
                            </div>
                            <!-- zip code / country -->
                            <div class="flex pt3">
                                <div class="w-50 pr2">
                                    <input
                                        type="text"
                                        placeholder="Postal/Zip Code"
                                        id="postCode"
                                        name="postCode"
                                        v-model="state.postCode"
                                    />
                                </div>
                                <div class="w-50 pl2">
                                    <!-- <select v-model="state.country" name="country" class="w-100" id="country">
                                        <option value="" selected>Choose Country</option>
                                        <option value="Nigeria">Nigeria</option>
                                    </select> -->
                                    <select v-model="state.country" name="country" class="w-100" id="country">
                                <option value="" selected>Choose Country</option>
                                <option v-for="country in sortedCountries" :key="country.alpha2Code" :value="country.name.common">
                                    {{ country.name.common }}
                                </option>
                            </select>
                                </div>
                            </div>
                            <!-- Description / Note  -->
                            <textarea
                                name="description"
                                class="mt3"
                                placeholder="Enter description if any"
                                id="description"
                                v-model="state.description"
                                cols="20"
                                rows="5"
                            ></textarea>
                            <button class="mt3" type="submit" :disabled="state?.disableSubmitButton || false">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </template></app-wrapper
    >
</template>

<script>
import { reactive, computed, onMounted, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AppWrapper from '@/layout/AppWrapper'
import Spinner from '@/components/Spinner'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import axios from 'axios'

export default {
    name: 'UpdateEntity',
    components: { AppWrapper, Spinner, VueTelInput },

    setup() {
        const store = useStore()
        const route = useRoute()
        const entity = computed(() => store.state?.Entity?.entityToUpdate)
        const { id } = route.params

        const countries = ref([])
        const sortedCountries = ref([])
        const selectedCountry = ref(null)

        const allCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all')
                countries.value = response.data
            } catch (error) {
                console.error('Error fetching countries:', error)
            }
        }
        const sortCountriesAlphabetically = () => {
            sortedCountries.value = [...countries.value].sort((a, b) => a.name.common.localeCompare(b.name.common))
        }

        // Watch for changes in countries and sort when updated
        watch(countries, () => {
            sortCountriesAlphabetically()
        })

        const state = reactive({
            disableSubmitButton: false,
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            phone: '',
            website: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postCode: '',
            country: '',
            description: '',
            isEmailValid: null,
            isValidatingEmail: false,
        })

        watch(
            () => entity.value,
            (prevValue, currValue) => {
                if (prevValue !== currValue) {
                    state.disableSubmitButton = false
                    Object.keys(state).forEach((keyName) => {
                        state[keyName] = entity?.value?.[keyName]
                    })
                }
            }
        )

        const validateEmail = async () => {
            state.isEmailValid = null
            state.invalidEmailConfirm = false
            state.isValidatingEmail = true
            await store
                .dispatch('Auth/validateEmail', state.email)
                .then((resp) => (state.isEmailValid = resp))
                .finally(() => (state.isValidatingEmail = false))
        }

        const onSubmit = () => {
            if (state.email !== '' && !state.isEmailValid) {
                store.dispatch('Alert/setAlert', {
                    message: "We don't accept unverified emails. Clear email input to proceed",
                    status: false,
                })
                return
            }
            state.disableSubmitButton = true
            const { disableSubmitButton, ...data } = state
            const payload = {}
            payload.id = id
            payload.formData = data
            payload.formData.firstName = data?.firstName?.trim()
            payload.formData.lastName = data?.lastName?.trim()
            store.dispatch('Entity/updateEntity', payload).then((result) => {
                if (result) {
                    state.disableSubmitButton = false
                }
            })
        }

        onMounted(() => allCountries())

        onMounted(() => {
            const { id } = route.params
            store.dispatch('Entity/getEntityToUpdate', id)
        })

        return { state, entity, onSubmit, validateEmail, sortedCountries, selectedCountry }
    },
}
</script>

<style>
.input-group {
    position: relative;
}
.input-group-label {
    position: absolute;
    top: 6px;
    right: 10px;
    cursor: pointer;
}
</style>
